import { default as React, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';

type IndexPageProps = {
    location: any;
};

type HsbtpCreateArgumentType = {
    region: string;
    portalId: string;
    formId: string;
    target: string;
};

type HbsptType = {
    forms: {
        create: (options: HsbtpCreateArgumentType) => void;
    };
};

declare const hbspt: HbsptType;

const ITDocLandingPage = ({ location }: IndexPageProps) => {
    const TARGET_ID = 'hubspot-target';

    useEffect(() => {
        const id = 'hubspot-form-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute('src', 'https://js.hsforms.net/forms/embed/v2.js');
            script.setAttribute('id', id);
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('charset', 'utf-8');

            // Append it to the head

            document.head.appendChild(script);

            // now wait for it to load...
            script.onload = () => {
                hbspt.forms.create({
                    region: 'na1',
                    portalId: '22505045',
                    formId: '881a6763-e8a1-45c2-bfdf-389fcef72e16',
                    target: `#${TARGET_ID}`,
                });
            };
        }
    }, []);

    return (
        <Layout location={location}>
            <div>
                <Helmet>
                    <title>Free Managed IT Services Benefits Guide</title>
                </Helmet>

                {/*begin header*/}
                <div className={'landing-header center mb-5'}>
                    <div className="container">
                        <div className="row">
                            <div className={'col-md-3 d-none d-md-block'}>
                                <img
                                    className="img-fluid"
                                    style={{ maxHeight: '200px' }}
                                    src="../images/landing-pages/squarehook-diamond-white-270.png"></img>
                            </div>
                            <div className={'col-md-9'}>
                                <h2>Benefits of Managed IT</h2>
                                <p>
                                    Download this FREE guide to see how Manged IT services can Transform your business
                                    and save you money.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*end header*/}

                {/*sign up form*/}
                <div className="container mb-5">
                    <div className="row">
                        <div className={'col-lg-2 col-md-4 center ms-auto my-auto'}>
                            <img className="img-fluid" src="../images/managed-it/managed-it-doc.png"></img>
                            <p style={{ fontSize: '.85rem' }}>
                                Sign up to get the document, future news, and industry insights.
                            </p>
                        </div>
                        <div className={'col-lg-6 col-md-8 me-auto'}>
                            <h3 className={'center'}>GET THE FREE DOWNLOAD TODAY</h3>
                            <div id={TARGET_ID}></div>
                        </div>
                    </div>
                </div>
                {/*end sign up form*/}

                <div className="container">
                    <h2>BUSINESS BENEFITS OF MANAGED IT</h2>
                    <p className="prose mb-4">
                        Managed IT services can provide numerous benefits for businesses of all sizes. By outsourcing IT
                        management to a third-party provider like SquareHook, companies can free up valuable time and
                        resources to focus on core business activities, reduce the risk of costly downtime, and improve
                        overall performance.
                    </p>
                    <h3 className="center">Just a few of the benefits:</h3>
                    <div className="row center">
                        <div className="col-md-4 mb-4">
                            <img className="img-fluid" src="../images/managed-it/it-benefits-costs.png" />
                            <p>
                                Businesses that used managed IT services experienced an average cost savings of 25%
                                compared to those that managed IT in-house.
                            </p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img className="img-fluid" src="../images/managed-it/it-benefits-downtime.png" />
                            <p>
                                Companies that use managed IT services experience an average 60% less downtime compared
                                to those that manage IT in-house.
                            </p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img className="img-fluid" src="../images/managed-it/it-benefits-focus.png" />
                            <p>
                                According to a CompTIA study, 46% of businesses that switched to managed IT services
                                increased their revenue as a direct result.
                            </p>
                        </div>
                    </div>
                </div>

                {/*CTA section*/}
                <div className="bgsection mt-5 p-5">
                    <div className="container mb-5">
                        <div className="col-lg-10 mx-auto">
                            <h2>Get IT Started</h2>
                            <p>
                                At SquareHook, we know you want to be <strong>doers, producers,</strong> the kind of
                                people who <strong>get things done</strong>. Don’t let IT costs, labor, maintenance,
                                downtime, and errors get in your way. We know that it’s way too frustrating to deal with
                                all that when you have more important things to do. Let us use our expertise to be your
                                dedicated IT solutions team.
                            </p>
                            <h4>Here&apos;s How It Works</h4>
                            <ol>
                                <li>
                                    Contact us for a <strong>FREE consultation</strong>, and we’ll discuss your needs.
                                </li>
                                <li>
                                    We&apos;ll offer a <strong>FREE analysis</strong> of your current IT processes and
                                    proposal showing how much you could save in time and money, and improvement on
                                    efficiency.
                                </li>
                                <li>
                                    We&apos;ll get to work and <strong>“make IT happen”!</strong> We collaborate with
                                    you every step of the way to ensure your success.
                                </li>
                            </ol>
                            <div className="center mainText col-lg-8 mx-auto">
                                <p className="emphasis">
                                    So contact us today for a FREE consultation, so you can stop stressing about IT and
                                    get your business flowing smoothly.
                                </p>
                                <a className="button-primary" href="/contact-us">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end cta */}
            </div>
        </Layout>
    );
};
export default ITDocLandingPage;
